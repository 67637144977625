<template>
	<section class="content redesign">
		<div class="card border rounded mb-3">
			<form class="card-body" @submit.prevent="submitForm">
				<div class="form-group">
					<div class="row">
						<div class="col">
							<label>Pilih Kurir</label>
						</div>
					</div>
					<div class="row" style="row-gap: 15px;">
						<div class="col-12 col-sm-4 col-lg-3" v-for="courier in totalCourier" v-bind:key="courier.courier_id">
							<div class="form-control h-100">
								<div class="icheck-material-orange w-100">
									<input type="checkbox" :id="courier.prefix" v-bind:value="courier.courier_id" v-model="filter.courier_id" :checked="true" :disabled="submitting" />
									<label :for="courier.prefix" class="form-check-label w-100">{{ courier.label }}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row">
						<div class="col">
							<label>Pengecualian Shipper</label>
						</div>
					</div>
					<div class="row" style="row-gap: 15px;">
						<div class="col-12 col-sm-4 col-lg-3" v-for="status in totalStatus" v-bind:key="status.id">
							<div class="form-control h-100">
								<div class="icheck-material-orange w-100">
									<input type="checkbox" :id="status.id" v-bind:value="status.id" v-model="filter.status_shipper" :checked="true" :disabled="submitting" />
									<label :for="status.id" class="form-check-label w-100">{{ status.nama }}</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="row" style="row-gap: 15px;">
						<div class="col-12 col-sm-auto ml-auto">
							<button type="submit" class="btn btn-primary btn-block px-5 py-3" :disabled="submitting">
								Generate <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							</button>
						</div>
						<div class="col-12 col-sm-auto">
							<button type="reset" class="btn btn-secondary btn-block px-5 py-3" :disabled="submitting">
								Reset <span v-if="submitting" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</section>
</template>
<script>
import $ from "jquery";
import Swal from "sweetalert2";
import "icheck-material";
import { authFetch } from "@/libs/hxcore";

export default {
	data() {
		return {
			totalCourier: [
				{
					courier_id: 0,
					label: 'Sedang diproses',
					prefix: null
				}
			],
			totalStatus: [
				{
					id: 'blacklist',
					nama: 'Blacklist'
				},
				{
					id: 'suspect',
					nama: 'Suspect'
				},
				{
					id: 'suspect rekening',
					nama: 'Suspect\u00A0Rekening'
				},
				{
					id: 'null',
					nama: 'Tanpa\u00A0Status'
				}
			],
			filter: {
				courier_id: [],
				status_shipper: []
			},
			submitting: false,
		};
	},
	created() {
        authFetch("/shipper/kurir")
		.then((res) => {
			if (res.status === 201) {} else if (res.status === 400) {}
			return res.json();
		})
		.then((js) => {
			this.totalCourier = js.data;
			const self = this;
			js.data.forEach(function (val) {
				self.filter.courier_id.push(val.courier_id);
			});
		});
	},
	methods: {
		submitForm: function () {
			this.customSwal.fire({
				title: 'Yakin ingin mengenerate Kurir Shipper? Langkah tidak dapat dibatalkan',
				showDenyButton: true,
				confirmButtonText: 'Lanjutkan',
				denyButtonText: 'Kembali',
			}).then((result) => {
				if (result.isDenied)
				{
					return false;
				}
				else if (result.isConfirmed)
				{
					// this.submitting = true;

					authFetch("/shipper/kurir", 
					{
						method: "POST",
						body: JSON.stringify(this.filter),
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					})
					.then(res => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
					})
					.then(js => {
						this.customSwal.fire({
							title: (js.status ? 'Berhasil' : 'Gagal') + ' generate kurir shipper',
							showConfirmButton: false,
							showCancelButton: true,
							cancelButtonText: js.status ? 'Kembali' : 'Coba Lagi',
						});
						this.submitting = false;
					});
				}
			});
		}
	},
	components: {},
	mounted() {
		$('.loading-overlay').removeClass('show');
		this.customSwal = Swal.mixin({
			customClass: {
				title: 'h4 tex-dark',
				actions: 'd-flex justify-content-between flex-row-reverse border-top pt-3',
				confirmButton: 'btn btn-primary w-49 font-weight-bold px-4 py-3',
				denyButton: 'btn btn-outline-primary w-49 font-weight-bold px-4 py-3',
				cancelButton: 'btn btn-primary w-auto font-weight-bold px-4 py-3',
			},
			buttonsStyling: false
		});
	},
};
</script>
<style type="text/css">
</style>